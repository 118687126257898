<template>
  <div class="components-container" v-if="isInitializedFields">
    <section>
      <div class="required-fields-container">
        <span class="py-3">
          <strong>Campos obrigatórios - Cadastro completo </strong>
        </span>
        <div class="" v-for="(typeField, findx) in completeRegistrationFields" :key="findx">
          <div class="">
            <div class="row">
            <div class="col-12 my-2" v-for="(group, g_indx) in typeField.fields" :key="typeField.type + '-group-' + g_indx">
              <p class="title-group py-2 mb-2">{{ group.group }}</p>
              <div class="row">
                <div class="col-3 my-1 item" v-for="(field, gf_indx) in group.fields" :key="typeField.type + '-field-' + gf_indx">
                  <input
                      class="form-control"
                      id="category-fieldname"
                      name="category-fieldname"
                      type="checkbox"
                      v-model="selectedCompleteFields[group.group][field.field]"
                      :value="field.field"
                      :disabled="field.default"
                      @change="updatePatientRequiredFields"
                  />
                  <span>{{ field.label }}</span>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
      
      <div class="required-fields-container mt-3">
        <span class="py-3">
          <strong>Campos obrigatórios - Cadastro rápido </strong>
        </span>
        <div class="" v-for="(typeField, findx) in quickRegistrationFields" :key="findx">
          <div class="">
            <div class="row">
            <div class="col-12 my-2" v-for="(group, g_indx) in typeField.fields" :key="typeField.type + '-group-' + g_indx">
              <p class="title-group py-2 mb-2">{{ group.group }}</p>
              <div class="row">
                <div class="col-3 my-1 item" v-for="(field, gf_indx) in group.fields" :key="typeField.type + '-field-' + gf_indx">
                  <input
                      class="form-control"
                      id="category-fieldname"
                      name="category-fieldname"
                      type="checkbox"
                      v-model="selectedQuickFields[group.group][field.field]"
                      :value="field.field"
                      :disabled="field.default"
                      @change="updatePatientRequiredFields"
                  />
                  <span>{{ field.label }}</span>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>

    </section>
  </div>
</template>

<script>
import { getCurrentClinic } from '@/utils/localStorageManager'
export default {
  props: ['type', 'healthPlanId'],
	name: "RegisterView",
	components: {

  },
	async mounted() {
    await this.getClinicSettings();
    this.initializeSelectedFields();
  },
	data() {
    return {
      clinic_id: getCurrentClinic().id,
      clinicSetting: {},
      isInitializedFields: false,
      selectedCompleteFields: {},
      selectedQuickFields: {},
      completeRegistrationFields: [
        {
          "fields": [
            {
              group: 'Paciente',
              fields: [
                {label: 'Nome completo', field: 'patient_name', default: true},
                {label: 'Nome social', field: 'patient_nickname', default: false},
                {label: 'CPF', field: 'patient_cpf', default: false},
                {label: 'RG', field: 'patient_rg', default: false},
                {label: 'Nome do responsável', field: 'patient_name_responsible', default: false},
                {label: 'Telefone do responsável', field: 'patient_telephone_responsible', default: false},
                {label: 'Nome da mãe', field: 'patient_mother_name', default: false},
                {label: 'Telefone da mãe', field: 'patient_telephone_mother', default: false},
                {label: 'Nome do pai', field: 'patient_father_name', default: false},
                {label: 'Cartão Nacional de Saúde (CNS)', field: 'patient_cns', default: false},
                {label: 'Número do prontuário', field: 'patient_medical_record_number', default: true},
                {label: 'Mais informações', field: 'patient_info', default: false},
              ]
            },
            {
              group: 'Informações pessoais',
              fields: [
                {label: 'Profissão', field: 'patient_job', default: false},
                {label: 'Data de nascimento', field: 'patient_birthday', default: true},
                {label: 'Sexo', field: 'patient_gender', default: false},
                {label: 'Raça', field: 'patient_ethnicity', default: false},
                {label: 'Etnia', field: 'patient_ancestry', default: false},
                {label: 'Estado civil', field: 'patient_marital_status', default: false},
                {label: 'Nacionalidade', field: 'patient_nationality', default: false},
                {label: 'Religião', field: 'patient_religion', default: false},
              ],
            },
            {
              group: 'Contato',
              fields: [
                {label: 'E-mail', field: 'patient_email', default: false},
                {label: 'Telefone', field: 'patient_telephone', default: false},
                {label: 'Celular', field: 'patient_cellphone', default: false},
                {label: 'Celular 2', field: 'patient_cellphone2', default: false},
              ],
            },
            {
              group: 'Endereço',
              fields: [
                {label: 'CEP', field: 'patient_address_zipCode', default: false},
                {label: 'Logradouro', field: 'patient_address_address', default: false},
                {label: 'Número', field: 'addressNumber', default: false},
                {label: 'Complemento', field: 'patient_address_complement', default: false},
                {label: 'Bairro', field: 'patient_address_neighborhood', default: false},
                {label: 'Cidade', field: 'patient_address_city', default: false},
                {label: 'Estado', field: 'patient_address_state', default: false},
              ],
            },
          ],
        },
      ],
      quickRegistrationFields: [
        {
          "fields": [
            {
              group: 'Novo cadastro',
              fields: [
                {label: 'Nome completo', field: 'patient_name', default: true},
                {label: 'Data de nascimento', field: 'patient_birthday', default: true},
                {label: 'Sexo', field: 'patient_gender', default: false},
                {label: 'E-mail', field: 'patient_email', default: false},
                {label: 'Celular', field: 'patient_cellphone', default: false},
                {label: 'CPF', field: 'patient_cpf', default: false},
                {label: 'Nome da mãe', field: 'patient_mother_name', default: false},
                {label: 'Cartão Nacional de Saúde (CNS)', field: 'patient_cns', default: false},
                {label: 'Convênio', field: 'patient_healthplan', default: false},
                {label: 'Plano', field: 'patient_plan', default: false},
                {label: 'Número da carteirinha', field: 'patient_registration', default: false},
              ],
            },
          ]
        },
      ],
    }
  },
  computed: {
  },
  watch: {
  },
  methods: {
    async getClinicSettings() {
      await this.api
        .getClinicSettings(this.clinic_id)
        .then(res => {
          this.clinicSetting = res.data
        })
        .catch(err => {
          console.log('Falha ao buscar configurações da clínica!', err)
        })
    },
    initializeSelectedFields() {
      this.selectedCompleteFields = this.clinicSetting.complete_registration_fields && Object.values(this.clinicSetting.complete_registration_fields).length > 0 ? 
        this.clinicSetting.complete_registration_fields : {};

      this.completeRegistrationFields.forEach((types) => {
        types.fields.forEach((groups) => {
          if(!this.selectedCompleteFields[groups.group]) this.$set(this.selectedCompleteFields, groups.group, {});
          groups.fields.forEach((field) => {
            if(!this.selectedCompleteFields[groups.group][field.field]) this.$set(this.selectedCompleteFields[groups.group], field.field, field.default);
            if(field.default) this.$set(this.selectedCompleteFields[groups.group], field.field, field.default);
          });
        });
      });

      this.selectedQuickFields = this.clinicSetting.quick_registration_fields && Object.values(this.clinicSetting.quick_registration_fields).length > 0 ? 
        this.clinicSetting.quick_registration_fields : {};

      this.quickRegistrationFields.forEach((types) => {
        types.fields.forEach((groups) => {
          if(!this.selectedQuickFields[groups.group]) this.$set(this.selectedQuickFields, groups.group, {});
          groups.fields.forEach((field) => {
            if(!this.selectedQuickFields[groups.group][field.field]) this.$set(this.selectedQuickFields[groups.group], field.field, field.default);
            if(field.default) this.$set(this.selectedQuickFields[groups.group], field.field, field.default);
          });
        });
      });

      this.isInitializedFields = true;
      
    },
    async updatePatientRequiredFields() {
      this.loading = true
      await this.api.updateClinicSettings(this.clinicSetting.id, {
        'complete_registration_fields': this.selectedCompleteFields,
        'quick_registration_fields': this.selectedQuickFields,
      })
          .then(() => {})
          .catch(err => this.$toast.error(err.message))
          .finally(() => (this.loading = false))
    }
  }
}
</script>

<style scoped>
.components-container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .required-fields-container {
    border: 1px solid var(--divider, #0000001F);
    padding: 16px;
    border-radius: 8px;

    .title-group {
      border-bottom: 1px solid #D9DFF2;
    }

    .item {
      display: flex;
      align-items: center;
      gap: 8px;

      input {
        width: 16px;
        height: 16px;
      }
    }
  }



}
</style>
